











import UsersCondition from './UsersCondition'
import BlackWhiteListTags from '@/includes/logic/Modules/Conditions/components/BlackWhiteListTags.vue'
import GetUserInfoServiceImpl from "@/includes/logic/GetUserInfoServiceImpl";

import UsersInfoMixin from "piramis-base-components/src/shared/logic/getUsersInfo/UsersInfoMixin"

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    BlackWhiteListTags
  }
})
export default class UsersConditionValue extends Mixins(UsersInfoMixin) {
  @Prop() model!: UsersCondition

  @Prop() usersInfoService!: GetUserInfoServiceImpl

  format(tag: string | null) {
    return this.formatter(this.usersInfoService.usersInfos, tag)
  }
}
